import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { layoutGenerator } from 'react-break';

// components and styles
import styles from '../../styles/headerbar.module.css'

// images
import offsiterLogo from '../../images/logo.png';

const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');

const HeaderBar = ( props ) => {

  const history = useHistory();

  // getting query parameter
  const { search } = useLocation()
  var isGuest = (search === '?guest') ? true : false;

  var desktop_notguest = (
    <div className={styles.bar}>
      <div className={styles.content}>
        <img src={offsiterLogo} className={styles.offsiterLogo} alt={""} onClick={() => history.push("/agendas")}/>
        {props.img ? <img src={props.img} className={styles.brexLogo} alt={"Brex Logo"}/> : <></>}
      </div>
    </div>
  )

  var desktop_guest = (
    <div className={styles.bar}>
      <div className={styles.content}>
        <img src={offsiterLogo} className={styles.offsiterLogo} alt={"Offsiter"} />
        {props.img ? <img src={props.img} className={styles.brexLogo} alt={"Brex Logo"}/> : <></>}
      </div>
    </div>
  )

  return (
    <OnAtLeastTablet>
      {(props.isGuest || isGuest)  ? desktop_guest : desktop_notguest}
    </OnAtLeastTablet>
  );
};

export default HeaderBar;