import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/* components */
import ListingPage from './components/City/ListingPage';
import AgendaEntryPage from './components/Agendas/AgendaEntryPage';
import AgendaViewingPage from './components/Agendas/AgendaViewingPage';
import GuestViewPage from './components/Guests/GuestViewPage';

const AppWithRouterAccess = () => {

  const activityPageRoute = 
    <Route
      exact path='/activity/:listingID'
      render={(props) => <ListingPage {...props } listingType={'activity'}/> }
    />;

  const lodgingPageRoute = 
    <Route
      exact path='/lodging/:listingID'
      render={(props) => <ListingPage {...props } listingType={'lodging'}/> }
    />;

  const restaurantPageRoute = 
    <Route
      exact path='/restaurant/:listingID'
      render={(props) => <ListingPage {...props } listingType={'restaurant'}/> }
    />;

  const servicePageRoute = 
    <Route
      exact path='/service/:listingID'
      render={(props) => <ListingPage {...props } listingType={'service'}/> }
    />;

  const agendaEntryPageRoute = 
    <Route
      exact path='/agendas'
      render={(props) => <AgendaEntryPage {...props} />}
    />;

  const agendaViewingPageRoute = 
    <Route
      exact path='/agendas/:agendaID'
      render={(props) => <AgendaViewingPage {...props} /> }
    />;

  const guestViewPageRoute = 
    <Route
      exact path='/guest/:agendaID'
      render={(props) => <GuestViewPage {...props} /> }
    />;


  return (
    <Switch>
      {activityPageRoute}
      {lodgingPageRoute}
      {restaurantPageRoute}
      {servicePageRoute}
      {agendaEntryPageRoute}
      {agendaViewingPageRoute}
      {guestViewPageRoute}
      <Route render={() => <Redirect to="/agendas" />} />
    </Switch>
  );
};

export default AppWithRouterAccess;