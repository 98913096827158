import React from 'react';
import { layoutGenerator } from 'react-break';

// components and styles
import styles from '../../styles/listingModule.module.css';

// setting up the mobile breakpoints
const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

const ListingCard = ( props ) => {

  var listingName = props.name;
  var listingImage = props.heroImage;

  // default image if the listing doesn't have an image
  var heroImageUrl = (listingImage) ? 
    listingImage[0]['url'] : require('../../images/activity.png')

  var desktopCard = (
    <div className={styles.overallCard} >
      <img className={styles.heroImage} src={heroImageUrl} alt={""}></img>
      <div className={styles.textRow}>
        <p className={styles.listingName}>{listingName}</p>
        {props.isGuest ? <></> : <p className={styles.listingCost}>{props.estCost}</p>}
      </div>
    </div>
  )

  var mobileCard = (
    <div className={styles.overallCardMobile} >
      <img className={styles.heroImage} src={heroImageUrl} alt={""}></img>
      <div className={styles.textRow}>
        <p className={styles.listingName}>{listingName}</p>
        {props.isGuest ? <></> : <p className={styles.listingCost}>{props.estCost}</p>}
      </div>
    </div>
  )

    return (
      <>
        <OnAtMostPhablet>
          {mobileCard}
        </OnAtMostPhablet>
        <OnAtLeastTablet>
          {desktopCard}
        </OnAtLeastTablet>
      </>
    )
}

export default ListingCard