import React, { useState, useEffect } from 'react';
import { layoutGenerator } from 'react-break';

// import styles and components
import AgendaItineraryModule from '../Agendas/AgendaItineraryModule.js'
import ListingModule from '../City/ListingModule';
import HeaderBar from '../Shared/HeaderBar';

import styles from '../../styles/guestView.module.css'
import gs from '../../styles/global.module.css'
import m_styles from '../../styles/guestViewMobile.module.css'

var controller = require('../../data/dataController');

const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

const GuestViewPage = (props) => {

  const [agendaInfo, setAgendaInfo] = useState(null);
  const [guestInfo, setGuestInfo] = useState(null);
  const [currTab, setCurrTab] = useState(0);

  useEffect(() => {
    controller.agenda_by_id(props.match.params.agendaID)
    .then(res => setAgendaInfo(res))

    // get guestList
    controller.guestlist_by_id(props.match.params.agendaID)
    .then(res => setGuestInfo(res))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // have to do some date bullshit again
  if (agendaInfo) {
    var startDate = new Date(agendaInfo['StartDate']);
    startDate.setDate(startDate.getDate() + 1);
    var endDate = new Date(agendaInfo['EndDate']);
    endDate.setDate(endDate.getDate() + 1);
    var diffDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    var days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    var startDateFormat = days[startDate.getDay()] + ", " + months[startDate.getMonth()] + " " + startDate.getDate();
    var endDateFormat = days[endDate.getDay()] + ", " + months[endDate.getMonth()] + " " + endDate.getDate();
  }


  // initializing itinerary module
  var itineraryModule = agendaInfo ?
    <AgendaItineraryModule agendaInfo={agendaInfo} isGuest={true}/> :
    <></>

  // initializing lodging module
  var lodgingModule = agendaInfo && agendaInfo["LodgingID (from LodgingOptions)"] ? 
    <ListingModule listingType={'Lodging'}
                     dataFunction={() => controller.lodging_by_ids(agendaInfo["LodgingID (from LodgingOptions)"])}
                     isGuest={true}
                     title={'Lodging'}/> :
    <></>

  // initializing lodging module
  var serviceModule = agendaInfo && agendaInfo["ListingID (from ServiceOptions)"] ? 
    <ListingModule listingType={'Service'}
                   dataFunction={() => controller.services_by_ids(agendaInfo["ListingID (from ServiceOptions)"])}
                   isGuest={true}
                   title={'Services'}/> :
    <></>

  // initializing activity module
  var activityModule = agendaInfo && agendaInfo["ActivityID (from ActivityOptions)"]?
    <ListingModule listingType={'Activity'}
                     dataFunction={() => controller.activities_by_ids(agendaInfo["ActivityID (from ActivityOptions)"])}
                     isGuest={true}
                     title={'Activities'}/> :
    <></>

  // initializing restaurant module
  var restaurantModule = agendaInfo && agendaInfo["RestaurantID (from RestaurantOptions)"] ?
    <ListingModule listingType={'Restaurant'}
                     dataFunction={() => controller.restaurants_by_ids(agendaInfo["RestaurantID (from RestaurantOptions)"])}
                     isGuest={true}
                     title={'Restaurants'}/> :
    <></>

  var agendaModule = 
    <>
      {itineraryModule}
      {lodgingModule}
      {serviceModule}
      {activityModule}
      {restaurantModule} 
    </>


/***** DESKTOP BREAKPOINT ******/

  var desktopTabBar = agendaInfo ? 
  <>
    <div className={styles.tabBar}>
      <p className={currTab === 0 ? styles.selectedTab : styles.regularTab}
         onClick={() => {setCurrTab(0); window.scrollTo({top:0})}}>
        {"Overview"}
      </p>
      <p className={currTab === 1 ? styles.selectedTab : styles.regularTab}
         onClick={() => {setCurrTab(1); window.scrollTo({top:0})}}>
        {"Agenda"}
      </p>
      {guestInfo && guestInfo.length > 0 ? 
        <p className={currTab === 2 ? styles.selectedTab : styles.regularTab}
           onClick={() => setCurrTab(2)}>
          {"Guest List"}
        </p> : <></>}
    </div>
    <hr style={{marginTop:'-2px'}}></hr>
  </> :
  <></>

  var desktopInfoModule = agendaInfo ? 
    <div className={styles.headerBox}>
      <p className={styles.itinTitle}>{agendaInfo['ItineraryTitle']}</p>
      <div className={styles.horizontalBox}>
        <p className={styles.dateText}>{agendaInfo['CityName']}</p>
        <span>&nbsp;&bull;&nbsp;</span>
        <p className={styles.dateText}>{startDateFormat}</p>
        <span>&mdash;</span>
        <p className={styles.dateText}>{endDateFormat}</p>
        <span>&nbsp;</span>
        <p className={styles.dateText}>{"(" + diffDays + " nights)"  }</p>
      </div>
      {desktopTabBar}
    </div> : 
    <></>;

  var desktopOverviewModule = agendaInfo ? 
    <div className={styles.headerBox}>
      {agendaInfo['OverviewImage'] ? <img className={styles.heroImage} src={agendaInfo['OverviewImage'][0]['url']} alt={""}/> : <></>}
      <p className={styles.message}>{agendaInfo['OverviewMessage']}</p>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <button className={styles.agendaButton} onClick={() => {setCurrTab(1); window.scrollTo({top:0})}}>{"View Agenda"}</button>
        {guestInfo && guestInfo.length > 0 ? <button className={styles.guestButton} onClick={() => setCurrTab(2)}>{"See Guest List"}</button> : <></>}
      </div>
    </div> : 
    <></>;

    var desktopGuestModule = guestInfo && guestInfo.length > 0 ? 
    guestInfo.map(guest => 
      <div className={styles.guestBox} key={guest["FirstName"] + guest['LastName']}>
        { guest['ProfilePic'] ? <img src={guest['ProfilePic'][0]['url']} className={styles.guestImage} alt={""}/>:
          <div className={styles.guestImage}>
            <p className={styles.guestImageText}>{guest["FirstName"][0] + guest['LastName'][0]}</p>
          </div>
        }
        <div className={gs.verticalFlexBox}>
          <p className={styles.guestName}>{guest["FirstName"] + " " + guest["LastName"]}</p>
          <p className={styles.guestTitle}>{guest["Title"]}</p>
          <p className={styles.guestFlexible}>{guest["FlexibleLine"]}</p>
        </div>
      </div>
    ) : 
    <></>;

/***** MOBILE BREAKPOINT *****/

  var mobileInfoTabBar = agendaInfo ? 
  <>
    <div className={m_styles.tabBar}>
      <p className={currTab === 0 ? m_styles.selectedTab : m_styles.regularTab}
         onClick={() => {setCurrTab(0); window.scrollTo({top:0})}}>
        {"Overview"}
      </p>
      <p className={currTab === 1 ? m_styles.selectedTab : m_styles.regularTab}
         onClick={() => {setCurrTab(1); window.scrollTo({top:0})}}>
        {"Agenda"}
      </p>
      {guestInfo && guestInfo.length > 0 ? 
        <p className={currTab === 2 ? styles.selectedTab : styles.regularTab}
           onClick={() => setCurrTab(2)}>
          {"Guest List"}
        </p> : <></>}
    </div>
    <hr style={{marginTop:'-2px'}}></hr>
  </> :
  <></>

  var mobileInfoModule = agendaInfo ? 
    <div className={m_styles.headerBox}>
      <p className={m_styles.itinTitle}>{agendaInfo['ItineraryTitle']}</p>
      <div className={m_styles.horizontalBox}>
        <p className={m_styles.dateText}>{agendaInfo['CityName']}</p>
        <span>&nbsp;&bull;&nbsp;</span>
        <p className={m_styles.dateText}>{startDateFormat}</p>
        <span>&mdash;</span>
        <p className={m_styles.dateText}>{endDateFormat}</p>
      </div>
      {mobileInfoTabBar}
    </div> : 
    <></>;

  var mobileOverviewModule = agendaInfo ? 
    <div className={m_styles.headerBox}>
      {agendaInfo['OverviewImage'] ? <img className={styles.heroImage} src={agendaInfo['OverviewImage'][0]['url']} alt={""}/> : <></>}
      <p className={m_styles.message}>{agendaInfo['OverviewMessage']}</p>
      <button className={m_styles.agendaButton} onClick={() => {setCurrTab(1); window.scrollTo({top:0})}}>{"View Agenda"}</button>
      {guestInfo && guestInfo.length > 0 ? <button className={m_styles.guestButton} onClick={() => setCurrTab(2)}>{"See Guest List"}</button> : <></>}
    </div> : 
    <></>;

  var mobileGuestModule = guestInfo && guestInfo.length > 0 ? 
    guestInfo.map(guest => 
      <div style={{paddingLeft: '10px'}} className={styles.guestBox} key={guest["FirstName"] + guest['LastName']}>
        { guest['ProfilePic'] ? <img src={guest['ProfilePic'][0]['url']} className={styles.guestImage} alt={""}/>:
          <div className={styles.guestImage}>
            <p className={styles.guestImageText}>{guest["FirstName"][0] + guest['LastName'][0]}</p>
          </div>
        }
        <div className={gs.verticalFlexBox}>
          <p className={styles.guestName}>{guest["FirstName"] + " " + guest["LastName"]}</p>
          <p className={styles.guestTitle}>{guest["Title"]}</p>
          <p className={styles.guestFlexible}>{guest["FlexibleLine"]}</p>
        </div>
      </div>
    ) : 
    <></>;

/************* RETURN STATEMENT *********/

  return (
    <>
      <OnAtMostPhablet>
        <div>
          {mobileInfoModule}
          {currTab === 0 ? mobileOverviewModule : 
            currTab === 1 ? agendaModule : mobileGuestModule}
        </div>
      </OnAtMostPhablet>
      
      <OnAtLeastTablet>
        <HeaderBar isGuest={true} img={agendaInfo && agendaInfo["CompanyLogo"] ? agendaInfo["CompanyLogo"][0]['url'] : null}/>
        <div className={styles.verticalFlexBox}>
          <div className={styles.overallBox}>
            {desktopInfoModule}
            {currTab === 0 ? desktopOverviewModule : 
              currTab === 1 ? agendaModule : desktopGuestModule}
          </div>  
        </div> 
      </OnAtLeastTablet>

    </>
  )
}

export default GuestViewPage;