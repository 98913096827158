import React, { useState, useEffect } from 'react';
import { layoutGenerator } from 'react-break';
import { useLocation } from 'react-router-dom';

// styles & components
import styles from '../../styles/detailsPage.module.css'
import gs from '../../styles/global.module.css'
import HeaderBar from '../Shared/HeaderBar'

import checkmark from '../../images/amenity_check.png';

// setting up the mobile breakpoints
const layout = layoutGenerator({ phablet: 550, tablet: 768 });
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');

var controller = require('../../data/dataController');

function parseMarkdown(markdownText) {

  if (typeof markdownText === 'undefined' || markdownText === '') { return '' }
    
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br />')

  return '<p>' + htmlText.trim() + '</p>'
}

const ListingPage = ( props ) => {

  const [listingInfo, setListingInfo] = useState(null);

  // getting query parameter
  const { search } = useLocation()
  var isGuest = (search === '?guest') ? true : false;

  // only called once on "component did mount"
  useEffect(() => {controller.listing_by_id([props.listingType, props.match.params.listingID])
    .then(res => setListingInfo(res))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  var backButton = props.history.action !== 'POP' ? 
    <p className={styles.cityName} onClick={() => {props.history.goBack()}}>{"Back"}</p> :
    <></>

  var headerText = listingInfo ? (
    <>
      {backButton}
      <p className={styles.listingName}>{listingInfo['ListingName']}</p>
    </>
  ) : <></>

  // amenities grid for the lodging module
  var amenitiesGrid = <></>;
  if (listingInfo && listingInfo['Amenities'] && listingInfo['Amenities'].length > 0) {

    var amenitiesList = listingInfo['Amenities'].map(amenity => 
      <div className={styles.amenityView}>
        <img style={{width:'20px', height:'20px', padding:'0 10px 0 10px'}} src={checkmark} alt={"checkmark"}/>
        <p className={styles.amenityText}> {amenity} </p>
      </div>
    )

    amenitiesGrid = <ul className={styles.amenitiesGrid}>{amenitiesList}</ul>
  }

  // amenities grid for the lodging module
  var amenitiesGridMobile = <></>;
  if (listingInfo && listingInfo['Amenities'] && listingInfo['Amenities'].length > 0) {

    var amenitiesListMobile = listingInfo['Amenities'].map(amenity => 
      <div className={styles.amenityView}>
        <img style={{width:'20px', height:'20px', padding:'0 10px 0 10px'}} src={checkmark} alt={"checkmark"}/>
        <p className={styles.amenityText}> {amenity} </p>
      </div>
    )

    amenitiesGridMobile = <ul className={styles.amenitiesGridMobile}>{amenitiesListMobile}</ul>
  }

  // initializing the body 
  var body = listingInfo ? (
    <div className={styles.leftPage}>
      <img className={styles.heroImage} src={listingInfo['HeroImage'][0]['url']} alt={""}/>
      <p className={styles.requestTitle2}>{"Description"}</p>
      <p className={styles.listingDescription} dangerouslySetInnerHTML={{__html:parseMarkdown(listingInfo['ListingDescription'])}}/>
      {isGuest ? <></> : <p className={styles.requestTitle2}>{listingInfo['EstimatedCost(Long)'] ? "Estimated Cost" : ""}</p>}
      {isGuest ? <></> : <p className={styles.listingDescription} dangerouslySetInnerHTML={{__html:parseMarkdown(listingInfo['EstimatedCost(Long)'])}}/>}
      {amenitiesGrid}
    </div>
  ) : <></>
    
  // initializing request module 
  var requestCard = listingInfo ? 
    <div className={styles.rightPage}>
      <div className={styles.card}>
      <p className={styles.cityName2}>{listingInfo['ListingProvider']}</p>
      {listingInfo['Website'] ?
        <>
          <p className={styles.requestTitle}>{"Website"}</p>
          <p className={styles.requestBody} 
             onClick={() => window.open(listingInfo['Website'])}
             style={{textDecoration:'underline', color:'#0000EE', cursor: 'pointer'}}>
             {listingInfo['Website']}</p>
        </> : <></>
      }
      {listingInfo['PhoneNumber'] ?
        <>
          <p className={styles.requestTitle}>{"Phone Number"}</p>
          <p className={styles.requestBody}>{listingInfo['PhoneNumber']}</p>
        </> : <></>
      }
      <p className={styles.requestTitle}>{"Address"}</p>
      <p className={styles.requestBody}>{listingInfo['Address']}</p>
      <hr className={styles.hairline}></hr>
      <button style={{width: 'auto'}}
              onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(listingInfo['Address']))}>
              {"Get Directions"}
      </button>
    </div>
    </div> : <></>

  var mobileView = listingInfo ? 
    <div className={styles.mobileView}>
      {headerText}
      <img className={styles.heroImageMobile} src={listingInfo['HeroImage'][0]['url']} alt={""}/>
      {listingInfo['Website'] ?
        <>
          <p className={styles.requestTitle}>{"Website"}</p>
          <p className={styles.requestBody} 
             onClick={() => window.open(listingInfo['Website'])}
             style={{textDecoration:'underline', color:'#0000EE', cursor: 'pointer'}}>
             {listingInfo['Website']}</p>
        </> : <></>
      }
      {listingInfo['PhoneNumber'] ?
        <>
          <p className={styles.requestTitle}>{"Phone Number"}</p>
          <p className={styles.requestBody}>{listingInfo['PhoneNumber']}</p>
        </> : <></>
      }
      <p className={styles.requestTitle}>{"Address"}</p>
      <p className={styles.requestBody}>{listingInfo['Address']}</p>
      <button style={{width: '100%'}}
        onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(listingInfo['Address']))}>
        {"Get Directions"}
      </button>
      <hr className={styles.hairline}></hr>
      <p className={styles.requestTitle}>{"Description"}</p>
      <p className={styles.listingDescriptionMobile} dangerouslySetInnerHTML={{__html:parseMarkdown(listingInfo['ListingDescription'])}}/>
      {amenitiesGridMobile}
    </div> : <></>

  return (
    <>
      <OnAtLeastTablet>
        <HeaderBar isGuest={isGuest}/>
        <div className={styles.fullPage}>
          {headerText}
          <div className={gs.horizontalFlexBox}>
            {body}
            {requestCard}
          </div>
        </div>
      </OnAtLeastTablet>
      <OnAtMostPhablet>
        {mobileView}
      </OnAtMostPhablet>
    </>
  );

}

export default ListingPage