var crypto = require('crypto');

let curr_url = 'https://offsiter-brex.herokuapp.com/proposals';

/************************* LOCATION TABLE *************************/

let location_array;
let location_timestamp = new Date();

const get_locations = () => new Promise( resolve => {
  if (!location_array || new Date() - location_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/locations')
      .then(response => response.json())
      .then(data => {
        location_array = data['locations'];
        location_timestamp = new Date();
        resolve(location_array);
      });

  } else {
    resolve(location_array);
  }
});

exports.list_locations = function(req, res) {

  return new Promise( resolve => {

    var parsed_data = [];
    var count = 0;

    get_locations().then(() => {
      location_array.forEach(function(location) {

        var loc_blob = {
          "id": count,
          "cityName": location['CityName'],
          "heroImage": location['HeroImage'] ? location['HeroImage'][0]['url'] : null
        }

        parsed_data.push(loc_blob)
        count++;
      });

      resolve(parsed_data)
    })


  });
}

exports.city_info = function(req, res) {

  var cityName = req;
  return new Promise( resolve => {

    get_locations().then(() => {
      location_array.forEach(function(location) {

        if (location['CityName'] === cityName) {
          resolve(location)
        }
      });

      resolve(null)
    })

  });
}

/************************* ITINERARY TABLE *************************/

let itinerary_array;
let itinerary_timestamp = new Date();

const get_itineraries = () => new Promise( resolve => {

  if (!itinerary_array || new Date() - itinerary_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/itineraries')
      .then(response => response.json())
      .then(data => {
        itinerary_array = data['itineraries'];
        itinerary_timestamp = new Date();
        resolve(itinerary_array);
      });

  } else {
    resolve(itinerary_array);
  }
});

exports.list_itineraries_by_city = function(req, res) {

  return new Promise( resolve => {

    var itin_array = []
    var count = 0;

    get_itineraries().then(() => {
      itinerary_array.forEach(function(itinerary) {

        if (itinerary['CityName'] && itinerary['CityName'][0] === req) {

          var single_itin = []

          for(var i = 1; i <= itinerary["Days"]; i++) {
            var titleString = 'ItineraryDay' + i.toString() + 'Title';
            var descriptionString = 'ItineraryDay' + i.toString() + 'Description';
            var detailsString = 'ItineraryDay' + i.toString() + 'Details';
            var imageString = 'ItineraryDay' + i.toString() + 'Image';

            single_itin[i-1] = {
              "id": i-1,
              "title": itinerary[titleString],
              "description": itinerary[descriptionString],
              "details": itinerary[detailsString],
              "image": itinerary[imageString],
            }
          }

          itin_array.push({
            id: count,
            title: itinerary['ItineraryTitle'],
            itinerary: single_itin
          });

          count++;
        }
        
      });

      resolve(itin_array)
    })

  });
}

/************************* LODGING TABLE *************************/

let lodging_array;
let lodging_timestamp = new Date();

const get_lodging = () => new Promise( resolve => {
  if (!lodging_array || new Date() - lodging_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/lodging')
      .then(response => response.json())
      .then(data => {
        lodging_array = data['lodging'];
        lodging_timestamp = new Date();
        resolve(lodging_array);
      });

  } else {
    resolve(lodging_array);
  }
});

exports.lodging_by_city = function(req, res) {

  return new Promise( resolve => {

    var lodgingArr = []
    var count = 0;

    get_lodging().then(() => {
      lodging_array.forEach(function(lodging) {

        if (lodging['CityName'] && lodging['CityName'][0] === req) {
          lodgingArr.push({
            id: count,
            listingInfo: lodging,
          });
          count++;
        }
      });

      resolve(lodgingArr)
    })

  });

}

exports.lodging_by_id = function(req, res) {

  var lodgingId = req;

  return new Promise( resolve => {

    get_lodging().then(() => {
      lodging_array.forEach(function(lodging) {

        if (lodging['ListingID'] === lodgingId) {
          resolve(lodging)
        }
      });

      resolve(null)
    })

  });
}

exports.lodging_by_ids = function(req, res) {
  var lodgingIds = req;

  return new Promise( resolve => {

    var lodgingOptionArr = []
    var count = 0;

    get_lodging().then(() => {
      lodging_array.forEach(function(lodging) {

        if (lodgingIds.includes(lodging['ListingID'])) {
          lodgingOptionArr.push({
            id: count,
            listingInfo: lodging,
          });
          count++; 
        }
      });

      resolve(lodgingOptionArr)
    })

  });
}

/************************* ACTIVITY TABLE *************************/

let activity_array;
let activity_timestamp = new Date();

const get_activities = () => new Promise( resolve => {
  if (!activity_array || new Date() - activity_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/activities')
      .then(response => response.json())
      .then(data => {
        activity_array = data['activities'];
        activity_timestamp = new Date();
        resolve(activity_array);
      });

  } else {
    resolve(activity_array);
  }
});

exports.activities_by_city = function(req, res) {

  return new Promise( resolve => {

    var activityArr = []
    var count = 0;

    get_activities().then(() => {
      activity_array.forEach(function(activity) {

        if (activity['CityName'] && activity['CityName'][0] === req) {
          activityArr.push({
            id: count,
            listingInfo: activity,
          });
          count++;
        }
      });

      resolve(activityArr)
    })

  });

}

exports.activity_by_id = function(req, res) {

  var activityId = req;

  return new Promise( resolve => {

    get_activities().then(() => {
      activity_array.forEach(function(activity) {

        if (activity['ListingID'] === activityId) {
          resolve(activity)
        }
      });

      resolve(null)
    })

  });
}

exports.activities_by_ids = function(req, res) {
  var activityIds = req;

  return new Promise( resolve => {

    var activityOptionArr = []
    var count = 0;

    get_activities().then(() => {
      activity_array.forEach(function(activity) {

        if (activityIds.includes(activity['ListingID'])) {
          activityOptionArr.push({
            id: count,
            listingInfo: activity
          });
          count++;
        }
      });

      resolve(activityOptionArr)
    })

  });
}

/************************* RESTAURANT TABLE *************************/

let restaurant_array;
let restaurant_timestamp = new Date();

const get_restaurants = () => new Promise( resolve => {
  if (!restaurant_array || new Date() - restaurant_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/restaurants')
      .then(response => response.json())
      .then(data => {
        restaurant_array = data['restaurants'];
        restaurant_timestamp = new Date();
        resolve(restaurant_array);
      });

  } else {
    resolve(restaurant_array);
  }
});

exports.restaurants_by_city = function(req, res) {

  return new Promise( resolve => {

    var restaurantArr = []
    var count = 0;

    get_restaurants().then(() => {
      restaurant_array.forEach(function(restaurant) {

        if (restaurant['CityName'] && restaurant['CityName'][0] === req) {
          restaurantArr.push({
            id: count,
            listingInfo: restaurant,
          });
          count++;
        }
      });

      resolve(restaurantArr)
    })

  });

}

exports.restaurant_by_id = function(req, res) {

  var restaurantId = req;

  return new Promise( resolve => {

    get_restaurants().then(() => {
      restaurant_array.forEach(function(restaurant) {

        if (restaurant['ListingID'] === restaurantId) {
          resolve(restaurant)
        }
      });

      resolve(null)
    })

  });
}

exports.restaurants_by_ids = function(req, res) {
  var restaurantIds = req;

  return new Promise( resolve => {

    var restaurantOptionArr = []
    var count = 0;

    get_restaurants().then(() => {
      restaurant_array.forEach(function(restaurant) {

        if (restaurantIds.includes(restaurant['ListingID'])) {
          restaurantOptionArr.push({
            id: count,
            listingInfo: restaurant
          })
          count++;
        }
      });

      resolve(restaurantOptionArr)
    })

  });
}

/************************* SERVICE TABLE *************************/

let service_array;
let service_timestamp = new Date();

const get_services = () => new Promise( resolve => {
  if (!service_array || new Date() - service_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/services')
      .then(response => response.json())
      .then(data => {
        service_array = data['services'];
        service_timestamp = new Date();
        resolve(service_array);
      });

  } else {
    resolve(service_array);
  }
});

exports.services_by_city = function(req, res) {

  return new Promise( resolve => {

    var serviceArr = []
    var count = 0;

    get_services().then(() => {
      service_array.forEach(function(service) {

        if (service['CityName'] && service['CityName'][0] === req) {
          serviceArr.push({
            id: count,
            listingInfo: service,
          });
          count++;
        }
      });

      resolve(serviceArr)
    })

  });

}

exports.service_by_id = function(req, res) {

  var serviceId = req;

  return new Promise( resolve => {

    get_services().then(() => {
      service_array.forEach(function(service) {

        if (service['ListingID'] === serviceId) {
          resolve(service)
        }
      });

      resolve(null)
    })

  });
}

exports.services_by_ids = function(req, res) {
  var serviceIds = req;

  return new Promise( resolve => {

    var serviceOptionArr = []
    var count = 0;

    get_services().then(() => {
      service_array.forEach(function(service) {

        if (serviceIds.includes(service['ListingID'])) {
          serviceOptionArr.push({
            id: count,
            listingInfo: service
          })
          count++;
        }
      });

      resolve(serviceOptionArr)
    })

  });
}


/************************* SUBMISSIONS TABLE *************************/


exports.submitForm = function(req, res) {

  var dict = {}
  dict['SubmissionId'] = crypto.randomBytes(5).toString('hex').toUpperCase();

  for (let field in req[0]) {
    dict[field] = req[0][field].toString()
  }

  for (let field in req[1]) {
    dict[field] = req[1][field].toString()
  }

  for (let field in req[2]) {
    dict[field] = req[2][field].toString()
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({dict: dict})
  }

  return new Promise( resolve => {
    fetch(curr_url + '/proposal', requestOptions)
        .then(resolve(true))

    //todo actually handle this response
  })

}

/************************* PROPOSED AGENDA TABLE *************************/

let agenda_array;
let agenda_timestamp = new Date();

const get_agendas = () => new Promise( resolve => {

  if (!agenda_array || new Date() - agenda_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/agendas')
      .then(response => response.json())
      .then(data => {
        agenda_array = data['agendas'];
        agenda_timestamp = new Date();
        resolve(agenda_array);
      })

  } else {
    resolve(agenda_array);
  }
});

exports.agenda_by_id = function(req, res) {

  var agendaID = req;

  return new Promise( resolve => {

    get_agendas().then(() => {
      agenda_array.forEach(function(agenda) {

        if (agenda['AgendaCode'] === agendaID) {
          resolve(agenda)
        }
      });

      resolve(null)
    })

  });
}

/************************* GUESTS TABLE *************************/

let guest_array;
let guest_timestamp = new Date();

const get_guests = () => new Promise( resolve => {

  if (!guest_array || new Date() - guest_timestamp > 30000) {
    // if we have nothing in the cache, or it's been more than 30 seconds since last pull

    fetch(curr_url + '/guests')
      .then(response => response.json())
      .then(data => {
        guest_array = data['guests'];
        guest_timestamp = new Date();
        resolve(guest_array);
      })

  } else {
    resolve(guest_array);
  }
});

exports.guestlist_by_id = function(req, res) {

  var agendaID = req;

  return new Promise( resolve => {

    var guestlist = [];

    get_guests().then(() => {
      guest_array.forEach(function(guest) {

        if (guest['AgendaCode'] === agendaID) {
          guestlist.push(guest)
        }
      });

      resolve(guestlist)
    })

  });
}

/************************* LISTING HELPER *************************/

exports.listing_by_id = function(req, res) {

  var listingType = req[0];
  var listingID = req[1];

  if (listingType === 'activity') {
    return this.activity_by_id(listingID);
  } else if (listingType === 'lodging') {
    return this.lodging_by_id(listingID);
  } else if (listingType === 'restaurant') {
    return this.restaurant_by_id(listingID);
  } else if (listingType === 'service') {
    return this.service_by_id(listingID);
  }

  return null;

}

