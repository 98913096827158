import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

// import styles and components
import styles from '../../styles/agendaEntry.module.css'
import controller from '../../data/dataController';
import HeaderBar from '../Shared/HeaderBar';

const AgendaEntryPage = (props) => {

  const history = useHistory();

  const [agendaCode, setAgendaCode] = useState("");
  const [codeCorrect, setCodeCorrect] = useState(true);

  const buttonClick = () => {

    controller.agenda_by_id(agendaCode.toUpperCase()).then( result =>
    {
      if (result) {
        history.push('/agendas/' + agendaCode.toUpperCase())
      } else {
        setCodeCorrect(false);
      }
    });
  }

  const inputFieldStyle = codeCorrect ? styles.inputFieldCorrect : styles.inputFieldIncorrect

  const incorrectCode = codeCorrect ? 
      <></> : 
      <p className={styles.incorrectCode}>{"Code does not exist."}</p>

  return (
    <>
      <HeaderBar isGuest={false}/>
      <div className={styles.horizontalBox}>
        <p className={styles.titleText}>{"Enter Agenda Code"}</p>
        <p className={styles.subtitleText}>{"Your agenda code can be found in the email sent to you by the concierge."}</p>
        <input className={inputFieldStyle} onChange={e => setAgendaCode(e.target.value)}/>
        {incorrectCode}
        <button className={styles.button} onClick={buttonClick}>{"View Agenda"}</button>
      </div>
    </>
  );

};

export default AgendaEntryPage;