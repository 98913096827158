import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';

// styles and components 
import ListingModule from '../City/ListingModule';
import AgendaItineraryModule from './AgendaItineraryModule';
import HeaderBar from '../Shared/HeaderBar';

import styles from '../../styles/agendaViewingPage.module.css';
var controller = require('../../data/dataController');

function parseMarkdown(markdownText) {

  if (typeof markdownText === 'undefined' || markdownText === '') { return '' }
    
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>')
    .replace(/_(.*?)_/gim, '<i>$1</i>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br />')


  return '<p>' + htmlText.trim() + '</p>'
}

const AgendaViewingPage = (props) => {

  const [agendaInfo, setAgendaInfo] = useState(null);
  const [cityInfo, setCityInfo] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMessageModal2, setShowMessageModal2] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    controller.agenda_by_id(props.match.params.agendaID)
    .then(res => {
      setAgendaInfo(res);
      controller.city_info(res['CityName'][0])
      .then(res => setCityInfo(res))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // have to do some date bullshit again
  if (agendaInfo) {
    var startDate = new Date(agendaInfo['StartDate']);
    startDate.setDate(startDate.getDate() + 1);
    var endDate = new Date(agendaInfo['EndDate']);
    endDate.setDate(endDate.getDate() + 1);
    var diffDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    var days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    var startDateFormat = days[startDate.getDay()] + ", " + months[startDate.getMonth()] + " " + startDate.getDate();
    var endDateFormat = days[endDate.getDay()] + ", " + months[endDate.getMonth()] + " " + endDate.getDate();
  }

  var desktopInfoModule = agendaInfo ? 
    <div className={styles.headerBox}>
      <p className={styles.itinTitle}>{agendaInfo['ItineraryTitle']}</p>
      <div className={styles.horizontalBox}>
        <p className={styles.dateText}>{agendaInfo['CityName']}</p>
        <span>&nbsp;&bull;&nbsp;</span>
        <p className={styles.dateText}>{startDateFormat}</p>
        <span>&mdash;</span>
        <p className={styles.dateText}>{endDateFormat}</p>
        <span>&nbsp;</span>
        <p className={styles.dateText}>{"(" + diffDays + " nights)"  }</p>
      </div>
    </div> : 
    <></>;

  // initializing header image
  var heroImageUrl = (cityInfo && cityInfo['HeroImage']) ? 
    cityInfo['HeroImage'][0]['url'] : require('../../images/loading.png')

  var heroImageUrlStyle = { backgroundImage: "url(" + heroImageUrl + ")" }

  var headerImage = agendaInfo ? 
    <div style={heroImageUrlStyle} className={styles.headerImage}>
      <h1 className={styles.headerImageText}>{agendaInfo['CityName']}</h1>
    </div> : <></>

  // initializing header text
  var teaserText = cityInfo ? cityInfo['LocationTeaser'] : "";
  var overviewText = cityInfo && cityInfo['LocationOverview'] ? parseMarkdown(cityInfo['LocationOverview']) : "";

  var headerText = (
    <>
      <p className={styles.introText}>{teaserText}</p>
      <p className={styles.detailText} dangerouslySetInnerHTML={{__html: overviewText}}/>
    </>
  )

  const handleMessage = ( msg1 ) => {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ msg: msg1, code: props.match.params.agendaID})
    };
    fetch('https://offsiter-brex.herokuapp.com/proposals/concierge_message', requestOptions)
        .then(response => response.json())
        .then(data => console.log(data));

    setShowMessageModal(false)
  };

  const messageClick = () => {setShowMessageModal(true)};
  const acceptClick = () => {setShowMessageModal2(true); handleMessage('agenda accepted')};

  const handleClose = () => {setShowMessageModal(false)};
  const handleClose2 = () => {setShowMessageModal2(false)};

  var conciergeBox = (
    <div className={styles.conciergeBox}>
      <div className={styles.horizontalFlexBox} style={{maxWidth: '1186px', margin:'0 auto'}}>
        <div className={styles.textBox}>
          <p className={styles.conciergeBoxTitle}>{"Please review the proposed agenda"}</p>
          <p className={styles.conciergeBoxSubtitle}>{"Click Accept Agenda if you are satisfied with your concierge’s proposed agenda. If you have questions or something doesn’t look right, message your concierge and they will get back to you as soon as possible."}</p>
        </div>
        <div>
          <button onClick={messageClick}>{"Message Concierge"}</button>
          <button className={styles.conciergeAccept} onClick={acceptClick}>{"Accept Agenda"}</button>
        </div>
      </div>
    </div>
  )

  const modalStyles = {
    content: {
      position: 'absolute',
      top: '20%',
      left: '30%',
      width: '40%',
      right: 'auto',
      bottom: 'auto',
    },
  } 

  var messageModal = (
    <ReactModal isOpen={showMessageModal} style={modalStyles} appElement={document.getElementById('root')}>
      <div className={styles.modalVerticalFlexBox}>
        <p className={styles.modalTitle}>{"Send your concierge a message"}</p>
        <p className={styles.modalSubtitle}>{"The concierge will review your message and get back to you through email."}</p>
        <input className={styles.messageInput} onChange={e => setMessage(e.target.value)}/>
        <button className={styles.modalButtonYes} onClick={() => handleMessage(message)}>{"Send Message"}</button>
        <button className={styles.modalButtonNo} onClick={handleClose}>{"Cancel"}</button>
      </div>
    </ReactModal>
  )

  var messageModal2 = (
    <ReactModal isOpen={showMessageModal2} style={modalStyles} appElement={document.getElementById('root')}>
      <div className={styles.modalVerticalFlexBox}>
        <p className={styles.modalTitle}>{"Woohoo!! You're going on an offsite soon!"}</p>
        <p className={styles.modalSubtitle}>{"Your concierge will be in touch shortly with booking details."}</p>
        <button className={styles.modalButtonYes} onClick={handleClose2}>{"Done"}</button>
      </div>
    </ReactModal>
  )

  var infoModule = agendaInfo ? <></> : <></>;

  // initializing itinerary module
  var itineraryModule = agendaInfo ?
    <AgendaItineraryModule agendaInfo={agendaInfo} isGuest={false}/> :
    <></>

  // initializing lodging module
  var lodgingModule = agendaInfo && agendaInfo["LodgingID (from LodgingOptions)"] ? 
    <ListingModule listingType={'Lodging'}
                   dataFunction={() => controller.lodging_by_ids(agendaInfo["LodgingID (from LodgingOptions)"])}
                   isGuest={false}
                   title={'Lodging'}/> :
    <></>

    // initializing services module
  var serviceModule = agendaInfo && agendaInfo["ListingID (from ServiceOptions)"] ? 
    <ListingModule listingType={'Service'}
                   dataFunction={() => controller.services_by_ids(agendaInfo["ListingID (from ServiceOptions)"])}
                   isGuest={false}
                   title={'Services'}/> :
    <></>

  // initializing activity module
  var activityModule = agendaInfo && agendaInfo["ActivityID (from ActivityOptions)"]?
    <ListingModule listingType={'Activity'}
                   dataFunction={() => controller.activities_by_ids(agendaInfo["ActivityID (from ActivityOptions)"])}
                   isGuest={false}
                   title={'Activities'}/> :
    <></>

  // initializing restaurant module
  var restaurantModule = agendaInfo && agendaInfo["RestaurantID (from RestaurantOptions)"] ?
    <ListingModule listingType={'Restaurant'}
                   dataFunction={() => controller.restaurants_by_ids(agendaInfo["RestaurantID (from RestaurantOptions)"])}
                   isGuest={false}
                   title={'Restaurants'}/> :
    <></>

  return (
    <>
      <HeaderBar isGuest={false} img={agendaInfo && agendaInfo["CompanyLogo"] ? agendaInfo["CompanyLogo"][0]['url'] : null}/>
      {messageModal}
      {messageModal2}
      <div className={styles.verticalFlexBox}>
        {conciergeBox}
        <div className={styles.overallBox}>
          {desktopInfoModule}
          {headerImage}
          {headerText}
          {infoModule}
          {itineraryModule}
          {lodgingModule}
          {serviceModule}
          {activityModule}
          {restaurantModule}
        </div>  
      </div> 
    </>
  );
}

export default AgendaViewingPage;

